<template>
  <div class="about">
    <h1>Edit Site Content Here</h1>
    <select v-model="selectedType">
      <option v-for="(option,index) in contentTypes" :key="option.PageKey" :value="index">{{option.PageKey}}</option>
    </select> 
    <button @click="saveChanges"> Save </button>
    <quill-editor v-if="contentTypes.length > 0" :key="selectedType" toolbar="full" contentType="html" :modules="modules" v-model:content="contentTypes[selectedType].PageContent" />    
    </div>
</template>

<script>  
import { defineComponent } from 'vue'
import { QuillEditor } from '@vueup/vue-quill'
import BlotFormatter from 'quill-blot-formatter'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import api from '../api/api';

export default defineComponent({

  components: {
    QuillEditor
  },
 
  setup: () => { 
    const modules = {
      name: 'blotFormatter',  
      module: BlotFormatter,       
      options: {/* options */}
    }
    return { modules }
  },

  data () {
    return {
      contentTypes: [],
      selectedType: 0
    }
  },
  async mounted(){
    var res = await api.getPageContents();
    this.contentTypes = res.Items;
    this.selectedType = 0;
  },
  methods:{
    saveChanges() {
        api.saveContent({id:this.contentTypes[this.selectedType].PageKey, content:this.contentTypes[this.selectedType].PageContent});
    }
  }

})
</script>
