import axios from 'axios';
import auth from "../auth/auth.js"

const BASE_URL = "https://api.crazysignladies.com"

export default {
    getPageContents(){
        return axios.get(`${BASE_URL}/content`).then(response => {
            return response.data;
          });
    },
    saveContent(item){
        var jwtToken = auth.auth.getSignInUserSession().getAccessToken().jwtToken;
        var requestData = {
            headers: {
                'Authorization': 'Bearer '+ jwtToken
            }
        }
        return axios.put(`${BASE_URL}/content`, item, requestData).then(response => {
            return response.data;
          });
    }
}
  
  
  
  